import React, { useState } from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Header from "../components/header"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactBnbGallery from 'react-bnb-gallery';
import 'react-bnb-gallery/dist/style.css'

export default function Gallery({ data }) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [currentGalleryIndex, setCurrentGalleryIndex] = useState(-1);
  const { edges } = data.allContentfulAlbum;

  return (
    <Layout>
      <div className="bg-flamingo-100 p-4 sm:p-10 md:p-16  h-full">
        <Header headerText="Galeria zdjęciowa" />

        {currentGalleryIndex === -1 && edges && (<div className="text-4xl text-flamingo-800 pb-12">Albumy</div>)}
        <div className="grid grid-cols-1 md:grid-cols-2  xl:grid-cols-3 gap-4 sm:gap-8">
          
          {currentGalleryIndex === -1 && edges && edges.map((e, i) => (
            <div
              className="max-w-md md:max-w-2xl shadow-2xl transition duration-500 ease-in-out transform hover:shadow-md mx-auto bg-gray-50 overflow-hidden focus:ring-1"
              tabIndex="0"
              role="button"
              onClick={() => setCurrentGalleryIndex(i)}
              onKeyDown={() => setCurrentGalleryIndex(i)}>

              <div class="">
                <GatsbyImage
                  className="h-48 w-full object-cover transform hover:scale-110 transition duration-1000 ease-in-out"
                  imgClassName="rounded-b-none"
                  image={e.node.photos[0].card} alt={e.node.name} />
                <div class="p-8">
                  <div class="uppercase tracking-wide text-sm text-flamingo-800 font-semibold">{e.node.name}</div>
                  {/* <a href="#" class="block mt-1 text-lg leading-tight font-medium text-black hover:underline">Lina Druga</a> */}
                  {/* <p class="mt-2 text-gray-500"><p>{e.node.description.description}</p></p> */}
                </div>
              </div>
            </div>
          ))}
        </div>

        {currentGalleryIndex !== -1 && edges && (
          <div>
            <div className="text-4xl text-flamingo-800">{edges[currentGalleryIndex].node.name}</div>
            <div
              className="py-2 pb-4"
              tabIndex="-1"
              role="button"
              onClick={() => setCurrentGalleryIndex(-1)}
              onKeyDown={() => setCurrentGalleryIndex(-1)}>
              &larr; Albumy</div>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {edges && edges[currentGalleryIndex].node.photos.map((photo, index) => (
                <GatsbyImage
                  className="cursor-pointer transform hover:scale-95 transition duration-1000 ease-in-out"
                  image={photo.thumb}
                  onClick={() => { setCurrentPhotoIndex(index); setIsOpen(true) }}
                  onKeyDown={() => { setCurrentPhotoIndex(index); setIsOpen(true) }} />
              ))}
            </div>
            <div
              className="py-2 pb-4"
              tabIndex="-1"
              role="button"
              onClick={() => setCurrentGalleryIndex(-1)}
              onKeyDown={() => setCurrentGalleryIndex(-1)}>
              &larr; Albumy</div>
          </div>
        )}

        {currentGalleryIndex !== -1 && edges && (
          <ReactBnbGallery
            opacity={1}
            activePhotoIndex={currentPhotoIndex}
            showThumbnails={true}
            show={isOpen}
            onClose={() => setIsOpen(false)}
            photos={edges[currentGalleryIndex].node.photos.map(photo => ({
              photo: photo.full.images.fallback.src,
              thumbnail: photo.thumb.images?.fallback.src || ''
            }))} />
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Album {
    allContentfulAlbum {
      totalCount
      edges {
        node {
          id
          name
          photos {
            thumb: gatsbyImageData(
              width: 1600
              placeholder: BLURRED
            )
            card: gatsbyImageData(
              width: 1600
              placeholder: BLURRED
            )
            full: gatsbyImageData(width: 1600, placeholder: DOMINANT_COLOR, layout: FIXED)
          }
        }
      }
    }
  }

  `